import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import { AmplitudeService } from './shared/services/amplitude.service';
import { AuthService } from './auth/services/auth.service';
import { ToastComponent } from './shared/components/toast/toast.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, ToastComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(
    private amplitudeService: AmplitudeService,
    private authService: AuthService
  ) {
    this.amplitudeService.initAmplitude();
    //this.authService.getUserData().subscribe();
  }
}
