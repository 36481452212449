import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ToastMessage, ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-toast',
    imports: [NgClass, NgIf, NgSwitchCase, NgSwitch],
    templateUrl: './toast.component.html',
    styleUrl: './toast.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class ToastComponent {
  message$: Signal<ToastMessage | null>;

  constructor(private toastService: ToastService) {
    this.message$ = this.toastService.message$;
  }

  close() {
    this.toastService.clear();
  }
}
