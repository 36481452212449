import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'analise-de-credito',
    pathMatch: 'full', // Garante que a rota completa seja analisada para o redirecionamento
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'analise-de-credito',
    loadChildren: () =>
      import('./credit-analysis/credit-analysis.module').then(
        (m) => m.CreditAnalysisModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'gestao-de-proposta',
    loadChildren: () =>
      import('./proposal-analysis/proposal-analysis.module').then(
        (m) => m.ProposalAnalysisModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./auth/pages/logout/logout.component').then(
        (m) => m.LogoutComponent
      ),
  },
  {
    path: 'export-pdf',
    loadComponent: () =>
      import('./export-pdf/export-pdf.component').then(
        (m) => m.ExportPdfComponent
      ),
  },
  {
    path: 'nao-autorizado',
    loadComponent: () =>
      import('./not-allowed/not-allowed.component').then(
        (m) => m.NotAllowedComponent
      ),
  },
];
