import * as Sentry from '@sentry/angular';

import {
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, withRouterConfig } from '@angular/router';

import Aura from '@primeng/themes/aura';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { headersInterceptor } from './shared/interceptors/headers.interceptor';
import localePt from '@angular/common/locales/pt';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { registerLocaleData } from '@angular/common';
import { routes } from './app.routes';

registerLocaleData(localePt);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([headersInterceptor])),
    importProvidersFrom(
      NgxMapboxGLModule.withConfig({
        accessToken: environment.mapboxToken,
      })
    ),
    importProvidersFrom(),
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      const traceService = inject(Sentry.TraceService);
      console.log('Sentry TraceService initialized:', traceService);
    }),
    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: false || 'none',
        },
      },
    }),
  ],
};
