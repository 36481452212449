import * as Sentry from '@sentry/angular';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';

Sentry.init({
  dsn: 'https://7d1ee128dd266815c0d9cb44206d3396@o4508823609147392.ingest.us.sentry.io/4508874835296256',
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
