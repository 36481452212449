import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';

import { AuthService } from '../../auth/services/auth.service';
import { RedirectService } from '../services/redirect.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const redirect = inject(RedirectService);

  const queryString = state.url.split('?')[1] || '';
  const params = new URLSearchParams(queryString);
  const token = params.get('token');
  const currentToken = localStorage.getItem('authToken');

  if (token && authService.isLoggedIn()) {
    console.log('tem token e está logado');

    console.log(token !== currentToken);

    if (token !== currentToken) {
      localStorage.setItem('authToken', token);
      return authService.getUserData();
    }

    redirect.redirectToCurrentRouteWithoutQueryParams();
    return true;
  }

  if (token && !authService.isLoggedIn()) {
    localStorage.setItem('authToken', token);
    return authService.getUserData(window.location.href);
  }

  if (!token && !authService.isLoggedIn()) {
    authService.logout(window.location.href);
    return false;
  }

  return true;
};
